import React,{useEffect} from 'react';
import { NavLink, Link } from 'react-router-dom'
import $ from 'jquery';

//import '../../../public/assets/';
function HeaderComponent(props)
{
  
  function toggleMenu()
    {
        $('#navbar').toggleClass('navbar-mobile')
        var element =  document.getElementsByClassName('bi-list');
         if(typeof(element) != 'undefined' && element != null)
         {
          //this.classList.toggle('bi-list')
          //this.classList.toggle('bi-x')
          $('#navbar').toggleClass('bi-list')
          $('#navbar').toggleClass('bi-x')
          
         }
          
    }
  
  return(
    

    <header id="header" className="d-flex align-items-center sticky-top">
    <div className="container d-flex align-items-center">
      
      <Link to="/" className="logo me-auto">
        <img src="assets/img/Black.png" alt="kelanderday" />
      </Link>
       
      <nav id="navbar" className="navbar order-last order-lg-0">
        <ul>
        
          {/* <li><NavLink  className="nav-link scrollto"   onClick={() => window.location.replace("/#dashboard")}>Features</NavLink></li> */}
          <li><NavLink  className="nav-link scrollto"   to="/price">Pricing</NavLink></li>
          {/*<li><NavLink  className="nav-link scrollto"   to="/demo">Demo</NavLink></li>*/}
          <li> 
            <a href={props.logurl} className="get-login-btn scrollto d-inline-block d-lg-none">Login</a>
            <Link to="signup" className="get-started-btn scrollto d-inline-block d-lg-none">Start 3 months free</Link>
          </li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle" onClick={toggleMenu}></i>
      </nav>

      <a target="_blank" href={props.logurl} className="get-login-btn scrollto d-none d-lg-inline-block">Login</a>
	    <Link to="/signup" className="get-started-btn scrollto d-none d-lg-inline-block">Start 3 months free</Link>
      
    </div>
  </header>
  
  );
}
export default HeaderComponent;