import React,{useCallback,useRef} from 'react';
import axios from 'axios';
import $ from 'jquery';

//https://stackoverflow.com/questions/64099383/swiper-react-how-to-create-custom-navigation-pagination-components-using-react
import HomeCubeComponent from '../components/HomeCube';

//import '../../assets/vendor/swiper/swiper-bundle.min.css';
//import Swiper from '../../assets/vendor/swiper/swiper-bundle.min.js';
import { Navigation, Pagination, Scrollbar, A11y,Autoplay } from 'swiper';
import { EffectFade,Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './Home_assets/Swiper-slider.css';
import SliderComponent from '../components/SliderSection';

SwiperCore.use([Autoplay]);

function HomeComponent()
{
  const sliderNumber = [1, 2, 3, 4, 5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];
    const swiperRef = useRef(null);
    const [isSliderplaying,setSliderToggle]=React.useState(true);
    
    const [post, setPost] = React.useState(null);
    const [testimonials, setTestimonials] = React.useState(null);
    let cubes=[];

    
    const sliderRef = React.useRef(null)
    const handlePrev = useCallback(() => {
      if (!sliderRef.current) return;
      sliderRef.current.swiper.slidePrev();
    }, []);
    
    const handleNext = useCallback(() => {
      if (!sliderRef.current) return;
      sliderRef.current.swiper.slideNext();
    }, []);
 
    const handleStop = () => {
      if (sliderRef.current) sliderRef.current.swiper.autoplay.stop();
      setSliderToggle(false);
    };
    const handlePlay = () => {
      if (sliderRef.current) sliderRef.current.swiper.autoplay.start();
      setSliderToggle(true);
    };
    React.useEffect(() => 
    {
        axios.get('../../jData.json').then((response) => 
        {
          
          let res=response.data;
          let cubeData,testimonialsDiv=[];
          
          if(res.hasOwnProperty('home') && Object.keys(res).length>0)
          {
            if(res.home.hasOwnProperty('cube') && Object.keys(res.home.cube).length>0)
            {
                cubeData=res.home.cube;
                setPost(cubeData);
            }
            if(res.home.hasOwnProperty('testimonials') && Object.keys(res.home.testimonials).length>0)
            {
              testimonialsDiv=res.home.testimonials;
                setTestimonials(testimonialsDiv);
            }
          }
          
        });
      }, []);
     
      
        $(document).off().on('click', '.features_btn_viewall', function(e) 
        {  
            var btn_show = this; var btncount = 0;
            const parent = this.closest('.row');
            const children = parent.querySelectorAll(':scope > div.d-lg-block');
            children.forEach(element => {
              if (element.classList.contains('d-none')) {btncount++;
                element.classList.remove('d-none')
                element.classList.add('d-block')
                if (btncount == 1) {
                btn_show.innerHTML = 'View less';
                }
              } else if (element.classList.contains('d-block')) {btncount++;
                element.classList.remove('d-block')
                element.classList.add('d-none')
                if (btncount == 1) {
                btn_show.innerHTML = 'View all';
                }
              }
            });
            
        });
      
      if (!post) return ""

      
  return(
  <>
   <section id="hero" className="d-flex align-items-center pt-1">
   <Swiper
          ref={sliderRef}
          spaceBetween={30}
          effect={'fade'}
          navigation={false}
          pagination={{
            clickable: true,
            el: '.swiper-pagination-1',
            renderBullet: function (index, className) {
              return '<span class="swiper-pagination-bullet"></span>';
            }
          }}
          autoplay={{
            delay: 8500,
            disableOnInteraction: false,
          }}
          modules={[ Navigation, Pagination,Autoplay]}
          className="mySwiper"
        >
           {sliderNumber.map((number) => (
              <SwiperSlide>
              <img src={require('../pages/Home_assets/images/'+number+'.jpg')}  width="100%"/>
            </SwiperSlide>

           ))}
          
        
          
          { /*<div>
            <div className="custom-nav-container swiper-button-prev" onClick={handlePrev} ></div>
            <ol class="swiper-pagination-1"></ol>
            <div className="custom-nav-container swiper-button-next" onClick={handleNext} ></div>
          </div> */}

          <div className="swiper-nav-wrapper">
            <div className="swiper-button-prev-1" onClick={handlePrev}>
              <i className="bi bi-chevron-left"></i>
            </div>
            <div className="swiper-pagination-1" ></div>
            <div className="swiper-button-next-1">
              <i className="bi bi-chevron-right" onClick={handleNext}></i>
            </div>
            {!isSliderplaying && <div className="mbutton play" onClick={handlePlay}>
            <i class="bi bi-play-circle"></i>
            </div>}
           {isSliderplaying &&
            <div onClick={handleStop} className='mbutton play'>
              <i class="bi bi-pause-circle"></i>
            </div>
           } 
          </div>
        </Swiper>
        
    </section>
    <main id="main">
    
    { /*<section id="testimonials" className="testimonials">
      <div className="container">
        <div className="section-title">
            <h2>Hear what our customers say</h2>
        </div>
        <div className="testimonials-slider swiper">
            <div className="swiper-wrapper">
            <Swiper
               spaceBetween={10}
               modules={[Navigation, Pagination, Scrollbar, A11y]}
               pagination={{ clickable: true }}
               breakpoints={{
                  // when window width is >= 640px
                  640: {
                    width: 640,
                    slidesPerView: 1,
                  },
                  // when window width is >= 768px
                  768: {
                    width: 768,
                    slidesPerView: 2,
                  },
                }} 
              >
                
              {testimonials.length>0 && testimonials.map(function(data, i)
              {
                return <SwiperSlide><SliderComponent key={i} cname={data.name} stars={data.starts} info={data.post} imgpath={data.starts} comments={data.desc}></SliderComponent></SwiperSlide>;
              })}
              
             </Swiper> 
            </div>
            <div className="swiper-pagination"></div>
        </div>
        </div>
    </section> */}
    </main>  
  </>
  );
}
export default HomeComponent;