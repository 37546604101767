import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import FooterComponent from "../include/FooterSection";
import HeaderSection from '../include/HeaderSection';
import HomeSection from "../pages/HomeSection";
import PriceSection from "../pages/PriceSection";
import PaymentComponent from "../pages/PaymentSection";
import SignUp from "../pages/SignupSection";
//import SignUpNew from "../pages/SignupSectionNew";
import FaqComponent from "../pages/FaqSection";
import OtpComponent from "../pages/OtpSection";

import MJSON from '../../local-json/Common';



const RouteSection =()=>{
  
    let [socialArr, setSocial] = React.useState(0);
    let [logLink, setLink] = React.useState(0);
    React.useEffect(() => 
    {
      if(Object.keys(MJSON).length>0 && MJSON.hasOwnProperty('social'))
      {
        setSocial(MJSON.social)
      }
      if(Object.keys(MJSON).length>0 && MJSON.hasOwnProperty('loginlink'))
      {
        setLink(MJSON.loginlink)
      }
    },[])
    
    if(Object.keys(socialArr).length>0)
    {
      socialArr=Object.entries(socialArr)
    }
    return(
      <>
     <BrowserRouter>
     <HeaderSection logurl={logLink} />
      <Routes>
      
        <Route  path="/" element={<HomeSection />} />
        <Route  path="/price" element={<PriceSection />} />
        <Route path="/signup" element={<SignUp />} />
        <Route  path="/verify" element={<OtpComponent />} />
        <Route path="/payment" element={<PaymentComponent />} />
        <Route path="/faq" element={<FaqComponent />} />
      </Routes>
      <FooterComponent socialArr={socialArr} />
     </BrowserRouter> 
     
     </>
    );
}
export default RouteSection;