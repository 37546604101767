import React from "react";
import axios from 'axios';
import ReactDOM from "react-dom";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import mjson from '../../local-json/Common';


const SignupSchema = yup.object().shape({
    fname: yup.string().required('First Name Required'),
    lname: yup.string().required('Last Name Required'),
    employees: yup.number().required().positive().integer('Employee Numbers Required'),
    mobile: yup.number().required().positive().integer('Mobile Required'),
    uemail: yup.string().email('Invalid email format').required('Email Required'),
    mpassword:yup.string().required('Please Enter your password').matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
  });
 
function SignUP()
{
    let [apiUrl, setapiUrl] = React.useState(0);
    React.useEffect(() => 
    {
      if(Object.keys(mjson).length>0 && mjson.hasOwnProperty('api_link'))
      {
        setapiUrl(mjson.api_link)
      }
    },[])
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState,
        formState: { errors }
      } = useForm({
        resolver: yupResolver(SignupSchema)
      });
      let { isSubmitting } = formState;
      
      const onSubmit = async(data) => {
        //isSubmitting=true;
        //'https://kdapi.taiftec.com/api/mainsite/register/client'
        // return new Promise(resolve => {
        //     setTimeout(() => {
        //         resolve();
        //     }, 2000);
        // });
        var _d = new Date();
        _d.setMonth(_d.getMonth() + 3);
        
        const newPost = {
            first_name:data.fname,
            last_name:data.lname,
            email:data.uemail,
            mobile_number:data.mobile,
            password:data.mpassword,
            employee_count:data.employees,
            date:new Date().toISOString().slice(0, 10),
            end_date:_d.toLocaleDateString()
        };
        try 
        {
          const resp = await axios({
            method:'post',
            'url':apiUrl+'mainsite/register/client',
            responseType:'json',
            headers: 
            {
              'Access-Control-Allow-Origin': '*',
            },
            data:newPost
          })
          .then(function (response) 
          {
            
            if(response.data.type=='success')
            {
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                response.data.email=newPost.email;
                localStorage.setItem('reg_response', JSON.stringify(response.data));
                isSubmitting=true;
                const timeId = setTimeout(() => {
                  isSubmitting=false;
                  navigate('/verify');
                },5000)
                
                
            }
            else
            {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                isSubmitting=false;
            }
          })
          .catch(function (error) 
          {
            isSubmitting=false;
            console.log(error);
            toast.error(error.response.data.message[0], {
              position: toast.POSITION.TOP_RIGHT
          });
          });
          
        } 
        catch (err) 
        {
          console.error(err);
        }
      };
      
      
      
    return(
        <main id="main">
          <ToastContainer />
            <section id="signup" className="signup section">
                <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Start the free 3 months trial</h2>
                </div>
                <div className="col-lg-7 mt-lg-0 d-flex me-auto ms-auto">
                    <form onSubmit={handleSubmit(onSubmit)} className="signupForm">
                    <div className="row">
                        <div className="form-group col-md-6">
                        <label for="fname">First Name</label>
                        <input type="text" name="fname" className="form-control" id="fname" {...register("fname")} />
                        {errors.fname && <p className="p-error">{errors.fname.message}</p>}
                        </div>
                        <div className="form-group col-md-6">
                        <label for="lname">Last Name</label>
                        <input type="text" className="form-control" name="lname" id="lname" {...register("lname")} />
                        {errors.lname && <p className="p-error">{errors.lname.message}</p>}
                        </div>
                    </div>
                    <div className="form-group">
                        <label for="employees">Number of employees</label>
                        <input type="text" className="form-control" name="employees" id="employees" {...register("employees")} />
                        {errors.employees && <p className="p-error">{errors.employees.message}</p>}
                    </div>
                    <div className="form-group">
                        <label for="email">Mobile No.</label>
                        <input type="text" className="form-control" name="mobile" id="mobile" {...register("mobile")} />
                        {errors.mobile && <p className="p-error">{errors.mobile.message}</p>}
                    </div>
                    <div className="form-group">
                        <label for="email">Email address</label>
                        <input type="email" className="form-control" name="uemail" id="uemail" {...register("uemail")} />
                        {errors.uemail && <p className="p-error">{errors.uemail.message}</p>}
                    </div>
                    <div className="form-group">
                        <label for="password">Password</label>
                        <input type="password" className="form-control" name="mpassword" id="mpassword" {...register("mpassword")} />
                        {errors.mpassword && <p className="p-error">{errors.mpassword.message}</p>} 
                    </div>
                    <div className="form-group">
                        <input type="checkbox" className="form-check-input" name="terms" id="terms" {...register("terms")} />
                        <label for="terms" className="form-check-label">I agree to the Terms and Privacy Policy</label>
                    </div>
                    <div className="kd-3">
                        <div className="loading">Loading</div>
                        <div className="error-message"></div>
                        <div className="sent-message">Your message has been sent. Thank you!</div>
                    </div>
                    <div className="text-center">
                        <button disabled={isSubmitting} type="submit">
                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}  
                            Create my account
                        </button>
                        
                    </div>
                    </form>
                </div>
                </div>
            </section>
        </main>
    );
}
export default SignUP;