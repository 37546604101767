const PaymentComponent=()=>
{
  return(
    <main id="main">
    <section id="payment" className="payment section">
      <div className="container">

        <div className="section-title">
          <h2>Your payment information</h2>
        </div>
		<div className="row">
		<div className="col-md-6 me-auto ms-auto me-md-0 ms-md-0 mb-3 mb-md-5 mb-lg-0 pricing">
			<div className="box d-flex flex-column">
				<img src="assets/img/Group65.svg" className="align-self-end" alt="mastercard" height="41" />
				<h3>$9 / User / Month </h3>
				<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-3 border-bottom"></div>
					<ul className="mb-5">
					<li><img src="assets/img/Group31.svg" className="float-start" alt="" /> 24/7 Support</li>
					<li><img src="assets/img/Group31.svg" className="float-start" alt="" /> Two-factor authentication</li>
					<li><img src="assets/img/Group31.svg" className="float-start" alt="" /> ios & android app</li>
					<li><img src="assets/img/Group31.svg" className="float-start" alt="" /> Project Limit</li>
					<li className="na"><img src="assets/img/Group30.svg" className="float-start" alt="" /> Unlimited free viewers</li>
					<li className="na"><img src="assets/img/Group30.svg" className="float-start" alt="" /> Storage</li>
					</ul>
					<span className="primary-color big pricing-total text-end pt-3 mt-auto">Your Total: $9.00</span>
			</div>
		</div>
        <div className="col-md-6 mt-5 mt-md-0 mb-5 mb-lg-0 me-auto ms-auto me-md-0 ms-md-0">
            <form action="" method="post" role="form" className="signupForm">
              <div className="row">
              <div className="form-group">
                <label for="card_name">Name on card</label>
                <input type="text" className="form-control" name="card_name" id="card_name" required />
              </div>
			  <div className="form-group">
                <label for="card_no">Card Number</label>
                <input type="text" className="form-control" name="card_no" id="card_no" required />
              </div>
			  <div className="form-group col-lg-6">
			  <label for="expiry_date">Expiry Date</label>
              <div className="col-6 ps-0 pe-2 float-start">
                  <input type="text" name="expiry_date_mm" className="form-control" id="expiry_date_mm" placeholder="MM" required />
			  </div>
				<div className="col-6 ps-2 float-end">
                  <input type="text" name="expiry_date_yy" className="form-control" id="expiry_date_yy" placeholder="YY" required />
                </div>
				</div>
                <div className="form-group col-lg-6 ps-lg-5">
                  <label for="cvv">CVV</label>
                  <input type="text" className="form-control text-end" name="cvv" id="cvv" required />
                </div>
			  <div className="form-group">
                <input type="checkbox" className="form-check-input" name="save_card" id="save_card" required /> 
                <label for="save_card" className="form-check-label">Save card for next timey</label> 
                <img src="assets/img/visa-brandmark.png" className="float-none float-lg-end mt-4 mt-lg-0 mb-3 mb-lg-0" alt="mastercard" height="41" />
              </div>
              <div className="kd-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button type="submit">MAKE PAYMENT</button></div>
            </div>  
            </form>
          </div>
		</div>
        
      </div>
    </section>

  </main>
  );
}
export default PaymentComponent;